<template>
  <DIV>

    <div id="container"></div>
  </DIV>
</template>
  
<style>
#container {
  width: 900px;
  height: 600px;
  margin: 0 auto;
}
</style>
  
  
<script>

import G6 from '@antv/g6';
import insertCss from 'insert-css';

// 我们用 insert-css 演示引入自定义样式
// 推荐将样式添加到自己的样式文件中
// 若拷贝官方代码，别忘了 npm install insert-css
insertCss(`
    .g6-component-tooltip {
      border: 1px solid #e2e2e2;
      border-radius: 4px;
      font-size: 12px;
      color: #000;
      background-color: rgba(255, 255, 255, 0.9);
      padding: 10px 8px;
      box-shadow: rgb(174, 174, 174) 0px 0px 10px;
    }
  `);

export default {
  data() {
    return {
      // G6实例
      graph: null,
      // 用来记录已经点击过的节点
      currentNode: [],
      // data
      g6Obj: {}
    }
  },
  mounted() {
    this.initGraph()
  },
  methods: {

    initGraph() {
      console.log("XXXXXXXXXnew!!!!")

      const data = {
        nodes: [],
        edges: [],
      };

      for (let i = 0; i < 100; i++) {
        const id = `node-${i}`;
        data.nodes.push({
          id,
          date: `2020${i}`,
          value: Math.round(Math.random() * 300),
        });

        data.edges.push({
          source: `node-${Math.round(Math.random() * 90)}`,
          target: `node-${Math.round(Math.random() * 90)}`,
        });
      }

      const container = document.getElementById('container');
      const width = container.scrollWidth;
      const height = (container.scrollHeight || 500) - 100;
      const timeBarData = [];

      const nodeSize = 20;

      for (let i = 0; i < 100; i++) {
        timeBarData.push({
          date: `2020${i}`,
          value: Math.round(Math.random() * 300),
        });
      }

      const timebar = new G6.TimeBar({
        x: 0,
        y: 0,
        width,
        height: 150,
        padding: 10,
        type: 'trend',
        trend: {
          data: timeBarData,
        },
      });

      // constrained the layout inside the area
      const constrainBox = { x: 10, y: 10, width: 580, height: 450 };

      const onTick = () => {
        let minx = 99999999;
        let maxx = -99999999;
        let miny = 99999999;
        let maxy = -99999999;
        let maxsize = -9999999;
        data.nodes.forEach((node) => {
          if (minx > node.x) {
            minx = node.x;
          }
          if (maxx < node.x) {
            maxx = node.x;
          }
          if (miny > node.y) {
            miny = node.y;
          }
          if (maxy < node.y) {
            maxy = node.y;
          }
        });
        const scalex = (constrainBox.width - nodeSize / 2) / (maxx - minx);
        const scaley = (constrainBox.height - nodeSize / 2) / (maxy - miny);
        data.nodes.forEach((node) => {
          node.x = (node.x - minx) * scalex + constrainBox.x;
          node.y = (node.y - miny) * scaley + constrainBox.y;
        });
      };

      const graph = new G6.Graph({
        container: 'container',
        width,
        height,
        linkCenter: true,
        plugins: [timebar],
        layout: {
          type: 'force',
          preventOverlap: true,
          onTick,
        },
        defaultNode: {
          size: nodeSize,
          type: 'circle',
          style: {
            fill: '#DEE9FF',
            stroke: '#5B8FF9',
          },
        },
        modes: {
          default: ['drag-node'],
        },
      });

      graph.data(data);
      graph.render();

      if (typeof window !== 'undefined')
        window.onresize = () => {
          if (!graph || graph.get('destroyed')) return;
          if (!container || !container.scrollWidth || !container.scrollHeight) return;
          graph.changeSize(container.scrollWidth, container.scrollHeight - 100);
        };
    }
  }
}
</script>